import { OverlayPanel } from 'primereact/overlaypanel';
import { useAuthContext } from '../../Provider/JwtAuthProvider';
import { useAuth } from 'auth/Hooks/useAuth';

interface IUserInfoProps {
    items: any;
    identityServerUrl: string;
    client: string;
}

function UserInfo({ items, identityServerUrl, client }: IUserInfoProps) {
    const { menu } = useAuthContext();
    const { logout } = useAuth();
    // if (!isImpersonated) {
    //     localStorage.removeItem('usernameImpersonateObject');
    // }
    // if (isImpersonated && impersonatedUser?.userType != 3) {
    //     localStorage.setItem('usernameImpersonateObject', JSON.stringify(impersonatedUser));
    // } else {
    //     usernameImpersonate = JSON.parse(localStorage.getItem('usernameImpersonateObject'));
    // }

    const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names?.length > 1) {
            initials += names[names?.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };


    const user = JSON.parse(localStorage.getItem('decoded_token'))
    const firstName = user?.username
    return (
        <div
            className="action__btn user"
            data-recx="popup_opener"
            onClick={(event) => menu?.current?.toggle(event)}>
            <div className="user__initial">
                <span>
                    {firstName ? <span>{getInitials(firstName)}</span> : null}
                </span>
                <div className="user__bullet"></div>
            </div>
            <OverlayPanel className={'no--padding'} ref={menu} dismissable>
                <div className="profile-module">
                    <div className="profile__top">
                        <div className="profile__img">
                            <div className="user__initial">
                                <span>
                                    {getInitials(firstName)}
                                </span>
                                <div className="user__bullet"></div>
                            </div>
                        </div>

                        <div className="profile__detail">
                            <p>
                                {user?.username}
                            </p>
                            <p className="user__email">{user?.['user-email']}</p>
                        </div>
                    </div>

                    {/* <i className="fas fa-user-circle"></i> */}

                    <div className="profile__list">
                        <ul>
                            {items.map((x, index) => {
                                return (
                                    <li key={index} className="p-menuitem">
                                        {x.template}
                                    </li>
                                );
                            })}

                            <li> <div
                                className=""
                                onClick={() => {
                                    logout();
                                }}>
                                <span className="p-menuitem-link">Signout</span>
                            </div></li>
                        </ul>
                    </div>

                </div>
            </OverlayPanel>
        </div>
    );
}
export default UserInfo;
