import { useState, useEffect } from 'react';
import logo from 'assets/images/logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useFetch from 'use-http';
import useEnterPress from 'hooks/useEnterPress';

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [isValidated, setIsValidated] = useState(false);

    const { get, post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL);

    const handleSubmit = async () => {
        if (!name) {
            setError('Username is required');
            return;
        }

        setError('');

        try {
            const res = await post(`/validate_user`, { email: name });

            if (res.response_code === 200) {
                setError('');
                setIsValidated(true);

                const resetPasswordResponse = await post(`/send_reset_password_link`, { email: name });
                if (resetPasswordResponse.response_code === 200) {
                    navigate('/login');
                } else {
                    setError('An error occurred while resetting the password.');
                }
            } else {
                setError('Invalid user');
            }
        } catch {
            setError('An error occurred while validating the user.');
        }
    };

    const handleLogin = () => {
        navigate('/auth/login');
    };

    useEnterPress(handleSubmit);

    return (
        <div className="login">
            <div className="login-form">
                <div className="form">
                    <div className="form__header">
                        <img src={logo} alt="Bot" />
                        <h2>{isValidated ? 'Forgot Password Confirmation' : 'Forgot your password?'}</h2>
                    </div>

                    <div className="login__inner">
                        {isValidated ? (
                            <div className="form__group">
                                <div className="form__header">
                                    <p>Please check your email to reset your password.</p>
                                </div>

                                <Button label="Go to Login" className="login-btn" onClick={() => navigate('/login')} />
                            </div>
                        ) : (
                            <>
                                <div className="form__group">
                                    <label htmlFor="name" className="form__label">
                                        Username
                                    </label>
                                    <div className="form__field">
                                        <InputText
                                            id="name"
                                            value={name}
                                            onChange={(e) => {
                                                setError('');
                                                setName(e.target.value);
                                            }}
                                            className="form__input"
                                            placeholder="Enter username"
                                        />
                                    </div>
                                    <div style={{ height: '20px', marginBottom: '20px' }}>
                                        {error && <p className="error-bubble error-message">{error}</p>}
                                    </div>
                                </div>

                                <div className="form__group">
                                    <Button
                                        label={loading ? 'Submitting' : 'Submit'}
                                        className="login-btn"
                                        onClick={handleSubmit}
                                        loading={loading}
                                        disabled={loading}
                                    />
                                    <Button label={'Login'} className="login-btn" onClick={handleLogin} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
