import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SideBarItem } from './SideBar';
import { useLocation } from 'react-router-dom';
import useSidebarPovider, { SidebarProvider } from 'auth/Provider/SidebarProvider';

const MiniSideBar = ({ routes }: { routes: SideBarItem[] }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { sideBarActive } = useSidebarPovider()
    const Route = (path) => {
        navigate(path);
    };
    return (
        <aside
            className={classNames("sidebar", { "close": !sideBarActive })}>
            <nav className="sidebar__nav">
                <ul className="nav-container">
                    {routes && routes.map((route, index) => {
                        if (route.canAccess && route.showInSidebar) {
                            return (
                                <li
                                    key={index} className={classNames({ active: location?.pathname.includes(route.path) })}
                                    onClick={() => Route(route.path)}>
                                    <a data-recx="page_link" className={classNames({ "nav-link": true })}>
                                        <i className={route.icon}></i>
                                        <span className='link_name'>{route.name} </span>
                                    </a>
                                </li>
                            )
                        } else {
                            return (null);
                        }
                    })}
                </ul>
            </nav>

        </aside>
    );
};
export default MiniSideBar;
