import React, { useState } from 'react';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useAuthContext } from 'auth/Provider/JwtAuthProvider';

const ChangePasswordSidebar = ({ setShowChangePasswordSidebar }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { user } = useAuthContext();

    const token_unparsed: any = localStorage.getItem(`decoded_token`);
    const token: any = JSON.parse(token_unparsed);
    const userEmail = token.sub;

    const { post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL, {
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' }
    });

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setError('');
    };

    const handleChangePassword = () => {
        if (!oldPassword || !newPassword || !confirmPassword) {
            setError('All fields are required.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setError('New password and confirm password do not match.');
            return;
        }
        const passwordError = getPasswordErrorMessage(newPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }
        setError('');
        changePassword();
    };

    const getPasswordErrorMessage = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&:])[A-Za-z\d@$!%*?&:]{8,}$/;
        if (!passwordPattern.test(password)) {
            return 'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
        }
        return '';
    };

    const changePassword = async () => {
        const res = await post(`/change_password`, {
            email: userEmail,
            old_password: oldPassword,
            new_password: newPassword
        });
        if (res.response_code === 200) {
            toast.success('Password changed successfully.');
            setShowChangePasswordSidebar(false);
        } else {
            setError(res?.message);
            // toast.error('Failed to change password. Please try again later.');
        }
    };

    return (
        <div className="change-password-container">
            <h2>Change Password</h2>
            <p className="change-password-description">
                Here you can change your password. Enter the old password and new password and click Change Password
                button.
            </p>
            <div className="form-container">
                <div className="form-row">
                    <div className="form-column twelve">
                        <div className="form-label">Old Password*</div>
                        <div className="form-field">
                            <Password
                                id="oldPassword"
                                value={oldPassword}
                                onChange={handleInputChange(setOldPassword)}
                                placeholder="Enter Old Password"
                                feedback={false}
                                
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-column twelve">
                        <div className="form-label">New Password*</div>
                        <div className="form-field">
                            <Password
                                id="newPassword"
                                value={newPassword}
                                onChange={handleInputChange(setNewPassword)}
                                placeholder="Enter New Password"
                                feedback={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-column twelve">
                        <div className="form-label">Confirm New Password*</div>
                        <div className="form-field">
                            <Password
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={handleInputChange(setConfirmPassword)}
                                placeholder="Re-enter New Password"
                                feedback={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-column twelve">
                        {error && <div className="error-bubble">{error}</div>}
                        <Button
                            label={'Change Password'}
                            onClick={handleChangePassword}
                            loading={loading}
                            className="change-password-button"
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordSidebar;
