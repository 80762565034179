import classNames from 'classnames';
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationRoutes from './ApplicationRoutes';
import SideBar, { SideBarItem } from './SideBar';
import { useAuthContext } from 'auth/Provider/JwtAuthProvider';
import useSidebarPovider from 'auth/Provider/SidebarProvider';
import HeaderProvider from 'auth/Provider/HeaderProvider';

interface ILayoutProps {
    children: any;
    SideBarDefaultItems: SideBarItem[];
    appName: string;
    modules: string[];
}

const Layout = ({ children, SideBarDefaultItems, appName, modules }: ILayoutProps) => {
    const { header } = HeaderProvider();
    const { SideBarItems, setPermissions, setLoading, setPermissionEvaluated, routes, setRoutes } = useAuthContext();
    const { setSideBarActive, sideBarActive, setKey } = useSidebarPovider();
    const history = useNavigate();

    // Set document title based on the header
    useEffect(() => {
        if (header) {
            document.title = `${appName} | ${header}`;
        } else {
            document.title = `${appName}`;
        }
    }, [appName, header]);

    useEffect(() => {
        setLoading(true);
        setPermissionEvaluated(true);
        setLoading(false);
        setRoutes(SideBarDefaultItems.map(x => {
            return {
                ...x,
                canAccess: true,
                children: x.children.map(child => {
                    return {
                        ...child,
                        canAccess: true
                    }
                })
            }
        }))
    }, [setPermissions, modules, SideBarDefaultItems, history, setLoading, setPermissionEvaluated, setRoutes]);


    useEffect(() => {
        if (!SideBarItems || SideBarItems.length === 0) {
            document.body.classList.add('error-body');
        } else {
            document.body.classList.remove('error-body');
        }
    }, [SideBarItems]);

    const snakeCase = (string: string) => {
        return string
            .replace(/\W+/g, ' ')
            .split(/ |\B(?=[A-Z])/)
            .map((word: string) => word.toLowerCase())
            .join('_');
    };

    return (
        <Fragment>
            {sideBarActive && (
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSideBarActive(false);
                        setKey(Math.random());
                    }}
                    className={classNames('sidebar-overlay', { active: sideBarActive })}
                />
            )}
            <div className="container">
                <div
                    className={classNames(
                        'page-dashboard',
                        'main',
                        `page - ${snakeCase(header?.trim())}`
                    )}>
                    <ApplicationRoutes {...{ routes: routes, loader: false }} />
                    {children}
                </div>
            </div>
        </Fragment>
    );
};

export default Layout;
