import React, { createContext, useState, useContext, useCallback } from 'react';

export interface SidebarContextType {
    sideBarActive: boolean;
    setSideBarActive: (sideBarActive: boolean) => void;
    key: number;
    setKey: (key: number) => void;
}

export const SidebarContext = createContext<SidebarContextType>(
    {
        sideBarActive: false,
        setSideBarActive: (sideBarActive: boolean) => { },
        key: Math.random(),
        setKey: (key: number) => { }
    });

export const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
    const [sideBarActive, setSideBarActive] = useState(false);
    const [key, setKey] = useState(Math.random());

    return (
        <SidebarContext.Provider
            value={{
                sideBarActive,
                setSideBarActive,
                key,
                setKey
            }}>
            {children}
        </SidebarContext.Provider>)
}

const useSidebarPovider = () => {
    return useContext(SidebarContext);
}

export default useSidebarPovider