import { useState, useCallback } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export interface DecodedToken {
  role: number;
  exp: number;
  [key: string]: any;
}

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);
  const [isAccountAdmin, setIsAccountAdmin] = useState<boolean>(false);
  const [isClientAdmin, setIsClientAdmin] = useState<boolean>(false);
  const [isBotAdmin, setIsBotAdmin] = useState<boolean>(false);
  const [isSME, setIsSME] = useState<boolean>(false);
  const navigate = useNavigate();

  const login = useCallback((token: string) => {
    localStorage.setItem('token', token);
    // @ts-ignore
    const decodedToken: DecodedToken = jwtDecode(token);

    // Set roles based on the decoded token
    setIsSystemAdmin(decodedToken.role === 1);
    setIsAccountAdmin(decodedToken.role === 2);
    setIsClientAdmin(decodedToken.role === 3);
    setIsBotAdmin(decodedToken.role === 4);
    setIsSME(decodedToken.role === 5);

    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    
    localStorage.removeItem('token');
    localStorage.clear();
    setIsAuthenticated(false);
    setIsSystemAdmin(false);
    setIsAccountAdmin(false);
    setIsClientAdmin(false);
    setIsBotAdmin(false);
    setIsSME(false);
    navigate('/auth/login');
  }, [navigate]);

  const getToken = () => localStorage.getItem('token');

  return {
    isAuthenticated,
    isSystemAdmin,
    isAccountAdmin,
    isClientAdmin,
    isBotAdmin,
    isSME,
    login,
    logout,
    getToken,
    setIsAuthenticated
  };
};
