import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';

export interface HeaderContextType {
  header: string;
  updateHeader: (updatedHeader: string) => void;
  description: string;
  updateDescription: (updatedDescription: string) => void;
  logo: any;
  loader: any;
  isClientsRefresh: any;
  UpdateIsRefresh: () => void;
  SetImperonatedClientOptions: any;
  SetImperonatedSelectedClient: any;
  ImpersonatedClientOptions: any;
}

export const HeaderContext = createContext<HeaderContextType>({
  header: '',
  updateHeader: (updatedHeader: string) => { },
  description: '',
  updateDescription: (updatedHeader: string) => { },
  logo: null,
  loader: null,
  isClientsRefresh: false,
  UpdateIsRefresh: () => { },
  SetImperonatedClientOptions: null,
  SetImperonatedSelectedClient: null,
  ImpersonatedClientOptions: null
});

export const HeaderProvider = ({
  logo,
  loader,
  children
}: {
  logo: any;
  loader: any;
  children: React.ReactNode;
}) => {
  const [header, setHeader] = useState('');
  const [description, setDescription] = useState('');
  const [isClientsRefresh, setisClientsRefresh] = React.useState(0);
  const [ImpersonatedClientOptions, SetImperonatedClientOptions] = React.useState(null);
  const [ImpersonatedSelectedClient, SetImperonatedSelectedClient] = React.useState(null);

  const updateHeader = useCallback((updatedHeader: string) => {
    setHeader(updatedHeader);
  }, []);

  const updateDescription = useCallback((updatedDescription: string) => {
    setDescription(updatedDescription);
  }, []);

  const UpdateIsRefresh = useCallback(() => {
    setisClientsRefresh((prev) => {
      let toggle = !prev;
      return Math.random();
    });
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        header,
        updateHeader,
        description,
        updateDescription,
        logo,
        loader,
        isClientsRefresh,
        UpdateIsRefresh,
        SetImperonatedClientOptions,
        SetImperonatedSelectedClient,
        ImpersonatedClientOptions
      }}>
      {children}
    </HeaderContext.Provider>
  );
};

const useHeaderPovider = () => {
  return useContext(HeaderContext);
};

export default useHeaderPovider;
