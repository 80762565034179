import { useEffect, useState } from 'react';
import logo from 'assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../Provider/JwtAuthProvider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useAuth } from 'auth/Hooks/useAuth';
import useEnterPress from 'hooks/useEnterPress';

interface LoginForm {
    username: string;
    password: string;
}

interface LoginResponse {
    access_token: string;
    token_decoded: any;
    response_code: number;
    message?: string;
    user: any;
    token_type: string;
}

const Login = () => {
    const navigate = useNavigate();
    const { setLoggedIn, setUser } = useAuthContext();
    const [formData, setFormData] = useState<LoginForm>({ username: '', password: '' });
    const { setIsAuthenticated } = useAuth();
    const [validationError, setValidationError] = useState('');
    const { post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL);

    const validateForm = (): string => {
        if (!formData.username && !formData.password) return 'Username and password are required';
        if (!formData.username) return 'Username is required';
        if (!formData.password) return 'Password is required';
        return '';
    };

    const handleInputChange = (field: keyof LoginForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, [field]: e.target.value }));
        setValidationError('');
    };

    const sendOtp = async () => {
        try {
            const res = await post(`/send_otp`, { email: formData.username });
            if (res.response_code !== 200) {
                console.error('Failed to send OTP');
            }
        } catch (error) {
            console.error('OTP send error:', error);
        }
    };

    const handleLogin = async () => {
        const error = validateForm();
        if (error) {
            setValidationError(error);
            return;
        }

        try {
            const validateRes = await post(`/validate_user`, { email: formData.username });
            if (validateRes.response_code !== 200) {
                setValidationError(validateRes.message || 'Login failed');
                return;
            }

            const loginRes: LoginResponse = await post(`/token`, {
                email: formData.username,
                password: formData.password
            });

            if (loginRes.response_code !== 200) {
                setValidationError(loginRes.message || 'Login failed');
                return;
            }

            localStorage.setItem('token', loginRes.access_token);
            localStorage.setItem('decoded_token', JSON.stringify(loginRes.token_decoded));

            setIsAuthenticated(true);
            setLoggedIn(true);
            setUser(loginRes.token_decoded);

            if (loginRes.token_decoded?.TwoFactorEnabled) {
                await sendOtp();
                navigate('/auth/2FA', { state: { user: loginRes.token_decoded } });
            } else {
                navigate('/botstudio');
            }
        } catch (error) {
            setValidationError('An error occurred during login.');
            toast.error('An error occurred during login.');
        }
    };

    const handleForgotPassword = () => {
        navigate('/auth/forgot-password');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            setLoggedIn(true);
            setIsAuthenticated(true);
            try {
                const decoded = JSON.parse(atob(token.split('.')[1]));
                setUser(decoded);
                navigate('/admin/users');
            } catch {
                setIsAuthenticated(false);
            }
        } else {
            setIsAuthenticated(false);
        }
    }, [navigate, setIsAuthenticated, setLoggedIn, setUser]);

    useEnterPress(handleLogin);

    return (
        <div className="login">
            <div className="login-form">
                <div className="form">
                    <div className="form__header">
                        <img src={logo} alt="Bot" />
                        <h2>Welcome</h2>
                        <p>Please enter your details.</p>
                    </div>

                    <div className="login__inner">
                        <div className="form__group">
                            <label htmlFor="username" className="form__label">
                                Username
                            </label>
                            <div className="form__field">
                                <InputText
                                    id="username"
                                    value={formData.username}
                                    onChange={handleInputChange('username')}
                                    className="form__input"
                                    placeholder="Enter your username"
                                />
                            </div>
                        </div>

                        <div className="form__group">
                            <label htmlFor="password" className="form__label">
                                Password
                            </label>
                            <div className="form__field">
                                <InputText
                                    id="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleInputChange('password')}
                                    className="form__input"
                                    placeholder="Enter your password"
                                />
                            </div>
                            {validationError && <p className="error-bubble error-message">{validationError}</p>}
                        </div>

                        <div className="form__group">
                            <Button
                                label="Login"
                                onClick={handleLogin}
                                className="login-btn"
                                loading={loading}
                                disabled={loading}
                            />
                        </div>
                        <div className="form__group">
                            <p className={`forgot-password`} onClick={handleForgotPassword}>
                                Forgot Password
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
