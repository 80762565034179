import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useAuthContext } from 'auth/Provider/JwtAuthProvider';
import Loader from 'components/Loader';

const UserProfileSidebar = ({ setShowUserProfileSidebar }) => {
    const [enable2FA, setEnable2FA] = useState(null);
    const [error, setError] = useState('');
    const token_unparsed: any = localStorage.getItem(`decoded_token`);
    const token: any = JSON.parse(token_unparsed);

    const [firstName, setFirstName] = useState('');

    
    const userEmail = token.sub;

    useEffect(() => {
        getUserProfile();
    }, []);

    const { post: getUserProfilePost, loading: getUserProfileLoading } = useFetch(
        process.env.REACT_APP_CONVERSE_IAM_URL,
        {
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' }
        }
    );

    const { post: saveUserProfilePost, loading: saveUserProfileLoading } = useFetch(
        process.env.REACT_APP_CONVERSE_IAM_URL,
        {
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' }
        }
    );

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setError('');
    };

    const handleCheckboxChange = (e) => {
        
        setEnable2FA(e.target.checked);
    };

    const getUserProfile = async () => {
        const res = await getUserProfilePost(`/get_user_profile`, {
            email: userEmail
        });
        if (res.response_code === 200) {
            setFirstName(res.user.name);
            setEnable2FA(res.user.two_fa);
        } else {
            setError(res?.message);
        }
    };

    const handleSaveChanges = async () => {
        const res = await saveUserProfilePost(`/save_user_profile`, {
            email: userEmail,
            first_name: firstName,
            enable_2fa: enable2FA
        });
        if (res.response_code === 200) {
            toast.success('Profile updated successfully.');
            setShowUserProfileSidebar(false);
        } else {
            setError(res?.message);
        }
    };

    if (getUserProfileLoading) return <Loader variant="static" />;

    return (
        <div className="user-profile-container">
            <h2>User Profile</h2>
            <div className="form-container">
                <div className="form-row">
                    <div className="form-column twelve">
                        <div className="form-label">First Name*</div>
                        <div className="form-field">
                            <InputText
                                value={firstName}
                                onChange={handleInputChange(setFirstName)}
                                placeholder="Enter First Name"
                                className={`fluid ${error && 'input-error'}`}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-column twelve">
                        <div className="form-label">Enable Two Factor Authentication</div>
                        <div className="form-field">
                            <Checkbox checked={enable2FA} onChange={handleCheckboxChange} className="custom-checkbox" />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-column twelve">
                        {error && <div className="error-bubble">{error}</div>}
                        <Button
                            label={'Save Changes'}
                            onClick={handleSaveChanges}
                            loading={saveUserProfileLoading}
                            className="save-changes-button"
                            disabled={saveUserProfileLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfileSidebar;
