import { SideBarItem } from "auth/Layout/SideBar";
import { Permission } from "auth/Types/Permission";
import { AuthUser } from "auth/Types/User";
import { OverlayPanel } from "primereact/overlaypanel";
import * as React from "react";

export interface AuthContextProps {
  user: AuthUser | null;
  permissions: Permission[];
  SideBarItems: SideBarItem[];
  routes: SideBarItem[];
  flatRoutes: SideBarItem[];
  components: SideBarItem[];
  loggedIn: boolean;
  isSystemAdmin: boolean;
  isAccountAdmin: boolean;
  isClientAdmin: boolean;
  isBotAdmin: boolean;
  isSme: boolean;
  dataLoaded: boolean;
  loading: boolean;
  roleId: string;
  menu: React.MutableRefObject<OverlayPanel | null>;
  setUser: (user: AuthUser | null) => void;
  setPermissions: (permissions: Permission[]) => void;
  setSideBar: (sidebars: SideBarItem[]) => void;
  setRoutes: (routes: SideBarItem[]) => void;
  setFlatRoutes: (routes: SideBarItem[]) => void;
  setComponents: (components: SideBarItem[]) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setRoleStates: (roleId: string) => void;
  setDataLoaded: (dataLoaded: boolean) => void;
  setLoading: (loading: boolean) => void;
  CloseMenu: (e: React.MouseEvent) => void;
  setPermissionEvaluated: (permissionEvaluated: boolean) => void;
  permissionEvaluated: boolean;
  setIsSystemAdmin: (isSystemAdmin: boolean) => void;
  setIsAccountAdmin: (isAccountAdmin: boolean) => void;
  setIsClientAdmin: (isClientAdmin: boolean) => void;
  setIsBotAdmin: (isBotAdmin: boolean) => void;
  setIsSme: (isSme: boolean) => void;
  setRoleId: (roleId: string) => void;
  identityServerUrl: string;
  client: string;
  baseUrl: string;
}

export const JwtAuthContext = React.createContext<AuthContextProps>(null!);

export const useAuthContext = () => React.useContext(JwtAuthContext);

const JwtAuthProvider = ({
  children,
  identityServerUrl,
  client,
  baseUrl
}: {
  client: string;
  identityServerUrl: string;
  children: React.ReactNode;
  baseUrl: string;
}) => {
  const menu = React.useRef<OverlayPanel | null>(null);

  // States
  const [user, setUser] = React.useState<AuthUser | null>(null);
  const [permissions, setPermissions] = React.useState<Permission[]>([]);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = React.useState(false);
  const [isAccountAdmin, setIsAccountAdmin] = React.useState(false);
  const [isClientAdmin, setIsClientAdmin] = React.useState(false);
  const [isBotAdmin, setIsBotAdmin] = React.useState(false);
  const [isSme, setIsSme] = React.useState(false);
  const [roleId, setRoleId] = React.useState("");
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [SideBarItems, setSideBar] = React.useState<SideBarItem[]>([]);
  const [routes, setRoutes] = React.useState<SideBarItem[]>([]);
  const [flatRoutes, setFlatRoutes] = React.useState<SideBarItem[]>([]);
  const [components, setComponents] = React.useState<SideBarItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [permissionEvaluated, setPermissionEvaluated] = React.useState(false);

  // Functions
  const setRoleStates = (roleId: string) => {
    setRoleId(roleId);

    // Map roleId to boolean role states
    setIsSystemAdmin(roleId === "1");
    setIsAccountAdmin(roleId === "2");
    setIsClientAdmin(roleId === "3");
    setIsBotAdmin(roleId === "4");
    setIsSme(roleId === "5");
  };

  const CloseMenu = (e) => {
    menu?.current?.toggle(e);
  };

  return (
    <JwtAuthContext.Provider
      value={{
        user,
        permissions,
        SideBarItems,
        routes,
        flatRoutes,
        components,
        loggedIn,
        isSystemAdmin,
        isAccountAdmin,
        isClientAdmin,
        isBotAdmin,
        isSme,
        dataLoaded,
        loading,
        roleId,
        menu,
        setUser,
        setPermissions,
        setSideBar,
        setRoutes,
        setFlatRoutes,
        setComponents,
        setLoggedIn,
        setRoleStates,
        setDataLoaded,
        setLoading,
        CloseMenu,
        permissionEvaluated,
        setPermissionEvaluated,
        setIsSystemAdmin,
        setIsAccountAdmin,
        setIsClientAdmin,
        setIsBotAdmin,
        setIsSme,
        setRoleId,
        identityServerUrl,
        client,
        baseUrl
      }}
    >
      {children}
    </JwtAuthContext.Provider>
  );
};

export default JwtAuthProvider;
