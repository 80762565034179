import { useAuth } from 'auth/Hooks/useAuth';

const AccessDenied = () => {
    const { logout } = useAuth();
    return (
        <div className="error-pages">
            <div className="error__inner">
                <i className="fa-light fa-circle-exclamation"></i>
                <h1>Access Denied</h1>
                <p>You do not have permission to access this application!</p>
                <p>
                    Click ok to logout from the system and log back in with
                    <br /> different user.
                </p>
                <form>
                    <input
                        type="button"
                        className="p-button"
                        value="Ok"
                        onClick={() => {
                            logout();
                        }}
                    />
                </form>
            </div>
        </div>
    );
};

export default AccessDenied;
