import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import RouteWithHeader from '../Component/RouteWithHeader';
import Loader from './Loader';

const ApplicationRoutes = ({ routes, loader }) => {
    return (
        <Routes>
            {routes && routes.map((x, index) => {
                const Component = x.component;
                if (x.canAccess && x.child == false) {
                    return <Route
                        key={index}
                        path={`${x.path}`}
                        element={
                            <RouteWithHeader key={x.unique_id} header={x.name} description={x.description}>
                                <React.Suspense fallback={<Loader />}>
                                    <Component />
                                </React.Suspense>
                            </RouteWithHeader>
                        }>
                        {x.children.map((child, childIndex) => {
                            const ChildComponent = child.component;
                            if (child.canAccess && child.child === true) {
                                return <Route
                                    key={`${index}-${childIndex}`}
                                    path={`${child.path}`}
                                    element={
                                        <RouteWithHeader key={child.unique_id} header={child.name} description={child.description}>
                                            <React.Suspense fallback={<Loader />}>
                                                <ChildComponent />
                                            </React.Suspense>
                                        </RouteWithHeader>
                                    }>
                                    {child.children.map((childChild, childChildIndex) => {
                                        const ChildChildComponent = childChild.component;
                                        if (childChild.canAccess && childChild.child === true) {
                                            return <Route
                                                key={`${index}-${childIndex}-${childChildIndex}`}
                                                path={`${childChild.path}`}
                                                element={
                                                    <RouteWithHeader key={childChild.unique_id} header={childChild.name} description={childChild.description}>
                                                        <React.Suspense fallback={<Loader />}>
                                                            <ChildChildComponent />
                                                        </React.Suspense>
                                                    </RouteWithHeader>
                                                } >
                                            </Route>
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Route>
                            } else {
                                return null;
                            }

                        })}
                    </Route>
                } else {
                    return null
                }
            })}
        </Routes>
    )
}

export default ApplicationRoutes;