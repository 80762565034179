import AccessDenied from 'auth/Component/AccessDenied';
import { useAuthContext } from 'auth/Provider/JwtAuthProvider';
import { createContext } from 'react';

export const PermissionContext = createContext<any>({});

const PermissionsProvider = ({ children }) => {
    const { permissions } = useAuthContext();
    const user = JSON.parse(localStorage.getItem('decoded_token'))

    const hasPermission = (permission: string) => {
        return permissions.some((report): boolean => report.shortName === permission);
    };

    if (user.role === 4) return <AccessDenied />

    return (
        <PermissionContext.Provider
            value={{
                readBotPermission: true,
                createBotPermission: true,
                deleteBotPermission: true,
                updateBotPermission: true,
                chatBotPermission: true,
                sessionPermission: true,
                trainBotPermission: true
            }}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionsProvider;
