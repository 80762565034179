import React from "react";
import { useEffect, Fragment } from "react";
import useHeaderPovider from "../Provider/HeaderProvider";


interface Iprops {
  header: string,
  description: string,
  children: any
}
const RouteWithHeader = (props: Iprops) => {
  const { updateHeader, updateDescription, header, description } = useHeaderPovider();

  useEffect(() => {
    updateHeader(props.header);
    updateDescription(props.description);
  }, [props.header, updateHeader, updateDescription, props.description]);

  return <Fragment>

    {props.children}
  </Fragment>
};

export default RouteWithHeader;
