export const Role = {
    ADMIN: '1',
    SUPER_USER: '2',
    USER: '3'
};

export const BotTypes = {
    '1': 'Business'
};



export const role_mapping = {
    1: 'System Admin',
    2: 'Account Admin',
    3: 'Client Admin',
    4: 'User'
}

export const RoleEnum = {
    SYSTEM_ADMIN: 1,
    ACCOUNT_ADMIN: 2,
    CLIENT_ADMIN: 3,
    USER: 4
}