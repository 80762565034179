import React, { useEffect, useState } from 'react';
import logo from 'assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../Provider/JwtAuthProvider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useAuth } from 'auth/Hooks/useAuth';
import Loader from '../Layout/Loader';
import { Link } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import { useLocation } from 'react-router-dom';


const MAX_OTP_ATTEMPTS = 3;

const TwoFA = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setIsAuthenticated } = useAuth();

    const { user } = location.state || {};
    const [email, setEmail] = useState(user?.email || '');
    const [isAccountLocked, setIsAccountLocked] = useState(false);
    const [otp, setOtp] = useState<number | null>(null);
    const [otpError, setOtpError] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpAttempts, setOtpAttempts] = useState(0);

    const { post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL, {
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' }
    });

    const handleSendOtp = async () => {
        try {
            const res = await post(`/send_otp`, { email: email });
            if (res.response_code === 200) {
                setIsOtpSent(true);
                toast.success('OTP sent successfully.');
            } else {
                toast.error('Failed to send OTP. Please try again later.');
            }
        } catch {
            toast.error('An error occurred while sending OTP.');
        }
    };

    const lockUser = () => {
        post(`/lock_user`, { email: user.sub })
            .then((res) => {
                if (res.response_code === 200) {
                    setOtpError('Account locked due to multiple failed OTP attempts.');
                    setIsAccountLocked(true);
                    setOtpAttempts(0);
                    setOtp(null);
                    setIsAuthenticated(false);
                }
            })
            .catch(() => {
                toast.error('An error occurred while trying to lock the user.');
            });
    };

    const handleVerifyOtp = async () => {
        if (otp === null) {
            setOtpError('OTP is required');
            return;
        }

        try {
            const res = await post(`/verify_otp`, { email: user.sub, otp: otp });
            if (res?.verified) {
                toast.success(res?.message);
                setIsAuthenticated(true);
                navigate('/botstudio');
            } else {
                setOtpError(res?.message);
                const attempts = otpAttempts + 1;
                setOtpAttempts(attempts);

                if (attempts >= MAX_OTP_ATTEMPTS) {
                    lockUser();
                    setOtpError('Your account has been locked due to multiple failed OTP attempts.');
                }
            }
        } catch {
            setOtpError('An error occurred during OTP verification.');
        }
    };

    useEffect(() => {
        if (!user) {
            navigate('/auth/login');
        }
    }, [user, navigate]);

    return (
        <div className="login">
            <div className="login-form">
                <div className="form">
                    <div className="form__header">
                        <img src={logo} alt="Bot" />
                        <h2>Two Factor Authentication</h2>
                    </div>

                    <div className="login__inner">
                        <div className="form__group">
                            <label htmlFor="username" className="form__label">
                                Enter One Time Password (OTP)
                            </label>

                            <div className="form__field">
                                <InputNumber
                                    id="otp"
                                    value={otp}
                                    useGrouping={false}
                                    onValueChange={(e) => {
                                        setOtpError('');
                                        setOtp(e.value);
                                    }}
                                    className="form__input otp-input"
                                    placeholder="Enter OTP"
                                />
                            </div>
                        </div>

                        <div className="form__group">
                            <p className="otp-expiry-note">OTP will expire after 5 minutes.</p>
                        </div>

                        <div className="form__group">
                            <p className={`forgot-password ${isAccountLocked ? 'disabled' : ''}`} onClick={handleSendOtp}>
                                Resend OTP
                            </p>
                        </div>

                        <div className="form__group">
                            <Button
                                label={loading ? 'Verifying...' : 'Verify OTP'}
                                size="large"
                                loading={loading}
                                onClick={handleVerifyOtp}
                                className="login-btn"
                                disabled={loading || isAccountLocked}
                            />
                        </div>

                        {isOtpSent && (
                            <div className="form__group">
                                <Link to="#" onClick={handleSendOtp} className="resend-otp-link">
                                    Resend Code
                                </Link>
                            </div>
                        )}
                        <div className="form__group">
                            {otpError && <p className="error-bubble error-message">{otpError}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoFA;
