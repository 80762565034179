
import { useNavigate, useLocation, useParams, Link, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { createContext, useState } from 'react';
import { SessionDetailResponse, SessionMessage, SessionDetail } from '../screens/ChatControl/interface';
import { BotReply } from 'interface';
import { BotSessionContextType } from 'types/context';

export const SessionContext = createContext<BotSessionContextType>({} as BotSessionContextType);

export const SessionProvider = ({ children }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const session_id = queryParams.get('session_id');
    const [chatBots, setChatBots] = useState<SessionDetailResponse>();
    const [chatSessionName, setSessionChatName] = useState('');
    const [botSessionDetail, setBotSessionDetail] = useState<SessionDetail[]>([]);
    const [botdetails, setBotdetails] = useState([]);
    const [confirmModalChat, setConfirmModalChat] = useState(false);
    const [confirmModalChatSession, setConfirmModalChatSession] = useState(false);
    const [chatModal, setChatModal] = useState(false);
    const [bots, setBots] = useState([]);
    const [agentID, setAgentID] = useState({});
    const [sessionID, setSessionId] = useState('');
    const [selectedSession, setSelectedSession] = useState(session_id);
    const [agentReply, setAgentReply] = useState<BotReply>();
    const [messages, setMessages] = useState([]);
    const [searchSessionQuery, setSearchSessionQuery] = useState('');
    const [searchPromptQuery, setSearchPromptQuery] = useState('');
    const [getMessagesBySessionIdLoading, setGetMessagesBySessionIdLoading] = useState(false);
    const [deleteAllSessionsLoading, setDeleteAllSessionsLoading] = useState(false);
    const [sessionHistoryMessages, setSessionHistoryMessages] = useState<SessionMessage[]>([]);

    return (
        <SessionContext.Provider
            value={{
                chatBots,
                setChatBots,
                chatSessionName,
                setSessionChatName,
                chatModal,
                setChatModal,
                confirmModalChat,
                setConfirmModalChat,
                botSessionDetail,
                setBotSessionDetail,
                sessionHistoryMessages,
                setSessionHistoryMessages,
                bots,
                setBots,
                agentID,
                setAgentID,
                selectedSession,
                setSelectedSession,
                messages,
                setMessages,
                sessionID,
                agentReply,
                setAgentReply,
                setSessionId,
                searchSessionQuery,
                setSearchSessionQuery,
                searchPromptQuery,
                setSearchPromptQuery,
                botdetails,
                setBotdetails,
                confirmModalChatSession,
                setConfirmModalChatSession,
                getMessagesBySessionIdLoading,
                setGetMessagesBySessionIdLoading,
                deleteAllSessionsLoading,
                setDeleteAllSessionsLoading
            }}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;
