import { DOMAIN, PRIMARY_COLOR } from 'constant';
import { DefaultChatbotFont, defaultBotPersonality, defaultGreetingMessage } from 'constant/index';
import {
    BotListConfig,
    ISourceWebsite,
    IBotDetail,
    Intent,
    Example,
    Slot,
    Action,
    SlotOption,
    StaticOption,
    IAdvancedSettingsData,
    TextSplitter,
    Embedding,
    Training,
    Response
} from 'interface';

export const defaultBotListConfig: BotListConfig = {
    pageNo: 1,
    pageSize: 10,
    sortField: 'updated_at',
    sortOrder: 1,
    status: ''
};

export const default_TextSplitter: TextSplitter = {
    use_parent: true,
    child: {
        type: 'Token',
        params: {
            chunk_size: 500,
            chunk_overlap: 0
        }
    },
    parent: {
        type: 'Token',
        params: {
            chunk_size: 1000,
            chunk_overlap: 0
        }
    }
};
export const default_Embedding: Embedding = {
    type: 'OpenAI',
    params: {
        model: 'azure-text-embedding-ada-002-1536'
    }
};
export const default_Training: Training = {
    embedding: default_Embedding,
    text_splitter: default_TextSplitter
};

export const default_Response: Response = {
    multi_query: false,
    rewrite_query: false,
    retriever_matching: {
        technique: 'similarity score threshold',
        matching_threshold: 0.75,
        number_of_chunks: 2
    },
    knowledge_source: {
        llm: 'azure-gpt4o-200b',
        temperature: 0,
        system_prompt: `You are an answer generation assistant. Use the given context from different sources (separated with asterism/triple asterisks) to answer the question. Different sources may contribute to the answer. Always answer using the given context only and don't use prior knowledge. Never make up an answer. Do not shorten or summarize the answer; always provide relevant details and Include all relevant information from different sources in your response. Answer should be formatted nicely using Markdown language and shouldn't be truncated in between a sentence. Always add <b></b> tags for highlighting headings and important parts of your answer. You can respond with 'No Answer' in case of greetings, simple statements or when the answer isn't present in provided context.`
    },
    intent_matching: {
        llm: 'azure-gpt4o-200b',
        temperature: 0,
        system_prompt: ''
    },
    answer_formatting: true
};
export const default_advance_settings: IAdvancedSettingsData = {
    training: default_Training,
    response: default_Response
};
export const defaultBotDetail: IBotDetail = {
    botInfo: {
        userId: null,
        email: null,
        botTypeId: 0,
        domain: DOMAIN,
        status: null,
        statusDescription: null,
        trainingProgress: 0,
        clientId: null,
        clientName: null,
        agentId: null,
        agentName: null,
        isEditable: false,
        botPersonality: null
    },
    botSettings: {
        botId: null,
        personality: defaultBotPersonality,
        llm: null,
        employeeCampaignCode: null,
        employeeCampaign: null,
        greetingMessage: defaultGreetingMessage,
        logo: null,
        banner: null,
        topAnswers: 3,
        maxTokens: 3,
        temperature: 0,
        headerColor: '#FFFFFF',
        bodyColor: '#E5E7EB',
        botMessageTextColor: PRIMARY_COLOR,
        userMessageTextColor: PRIMARY_COLOR,
        botMessageBubbleColor: '#E4E7EC',
        userMessageBubbleColor: '#475466',
        sendMessageBubbleColor: '#FFFFFF',
        sendMessageTextColor: PRIMARY_COLOR,
        sendIconBackgroundColor: PRIMARY_COLOR,
        sendIconForegroundColor: PRIMARY_COLOR,
        playCopyButtonColor: PRIMARY_COLOR,
        quickActionButtonColor: PRIMARY_COLOR,
        microphoneColor: PRIMARY_COLOR,
        font: DefaultChatbotFont,
        fontSize: 12,
        isPublic: true,
        isPasswordProtected: false,
        isSmallTalk: true,
        associateCampaign: false,
        advancedSettings: default_advance_settings,
        isIntentFlow: false,
        staticIntentGreetingMessage: defaultGreetingMessage,
        knowledgeSourceActionLabel: 'Knowledge Source',
        isSessionExpiryEnabled: false,
        sessionExpiryInMin: 10,
        disclaimer: '',
        chatQueries: [],
        pipeline: 'Azure',
        enableCaptcha: false,
        enableMessageActions: false,
    },
    websites: [],
    documents: [],
    apis: [],
    surveys: [],
    apiIntentTemplate: [],
    websiteTemplate: [],
    documentTemplate: []
};
export const defaultWebsiteInfo: ISourceWebsite = {
    id: null,
    baseUrl: 'https://',
    depth: 0,
    allowedDomains: null,
    status: null,
    statusDescription: null
};

export const defaultExample: Example = {
    id: null,
    text: null
};

export const defaultSlot: Slot = {
    sendTo: null,
    isRequired: true,
    slotName: '',
    slotType: null,
    previousInputHandleType: 'suggest previous',
    multiselector: null,
    requestParamName: '',
    slotFieldType: null,
    addressZipCodeSlot: null,
    minCharacters: 1,
    maxCharacters: 50,
    isTextOnly: false,
    isParseValueFromUserQuery: false,
    multipleOptionsType: null,
    multipleStaticOptions: [],
    referenceSlot: {
        slotId: null,
        slotName: '',
        slotParser: ''
    },
    multipleSlotOptions: [],

    slotId: null,

    description: '',
    fixedValue: '',
    countries: ''
};

export const defaultSlotOption: SlotOption = {
    slotId: null,
    slotName: '',
    slotParser: ''
};

export const defaultStaticOption: StaticOption = {
    staticOptionId: '',
    staticOptionName: ''
};

export const defaultAction: Action = {
    actionId: null,
    messageType: null,
    conditionType: null,
    actionType: null,
    actionStaticMessage: '',
    actionTriggerApi: null,
    condition: '',
    staticMessage: '',
    allowResponseParsing: false,
    isPerformAction: false,
    responseParse: '',
    allowAnswerGeneration: false,
    answerGeneration: '',
    allowSlotStorage: false,
    storageSlots: []
};

export const defaultDependentIntent = {
    intentTriggerStart: null,
    intentTriggerEnd: null
};

export const defaultIntent: Intent = {
    intentDetail: {
        id: null,
        name: '',
        intentTrigger: null,
        description: '',
        intentType: 'static',
        intentHeader: '',
        authenticationType: 'none',
        url: '',
        username: '',
        password: '',
        tokenUrl: '',
        requestType: null,
        intentTriggerStart: 'none',
        intentTriggerEnd: 'none',
        enableStaticActionButton: false,
        isLastIntent: false,
        isProminent: false
    },
    slots: [],
    action: defaultAction
};

export const defaultIntentExample: Example = {
    id: null,
    text: null
};
