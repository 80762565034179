import React from 'react';
import { Navigate, useLocation } from "react-router";

interface ISecureRouteProps {
  children?: React.ReactNode, identityServerUrl: string, client: string
}
const SecureRoute = ({ children, identityServerUrl, client }: ISecureRouteProps) => {
  const token_unparsed: any = localStorage.getItem(`decoded_token`);
  const token: any = JSON.parse(token_unparsed);
  const date = new Date();
  const unixTimestamp = Math.floor(date.getTime() / 1000);
  var isAuthenticated = token && token?.exp > unixTimestamp;
  const location = useLocation();
  
  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  
  return <Navigate to="/auth/login" state={{ from: location }} replace />
};

export default SecureRoute;