// Define a custom hook to manage the SignalR connection state.
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useState, useEffect } from 'react';

const useSignalRConnection = (hubUrl: string): HubConnection => {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    // useEffect(() => {
    //     const newConnection = new HubConnectionBuilder()
    //         .withUrl(hubUrl, {
    //             skipNegotiation: true,
    //             transport: HttpTransportType.WebSockets
    //         })
    //         .withAutomaticReconnect()
    //         .build();

    //     setConnection(newConnection);

    //     newConnection.start().catch((error) => {
    //         console.error(`Error starting ${hubUrl} connection:`, error);
    //     });

    //     return () => {
    //         newConnection.stop();
            
    //     };
    // }, [hubUrl]);

    return connection;
};

export default useSignalRConnection;
