import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo';
import { useAuthContext } from '../../Provider/JwtAuthProvider';
import useHeaderPovider from '../../Provider/HeaderProvider';
import useSidebarPovider from '../../Provider/SidebarProvider';
import { Sidebar } from 'primereact/sidebar';
import ChangePasswordSidebar from './ChangePasswordSidebar';
import UserProfileSidebar from './userProfileSidebar';
import { role_mapping } from 'enums';

interface IHeaderProps {
    menuItems: any;
    children: any;
    toast: any;
    baseOriginUrl: string;
    appName: string;
}


export function Header({ menuItems, children, toast, baseOriginUrl, appName = '' }: IHeaderProps) {
    const history = useNavigate();
    const { logo } = useHeaderPovider();
    const { CloseMenu, permissions, identityServerUrl, client } = useAuthContext();
    const [showChangePasswordSidebar, setShowChangePasswordSidebar] = useState(false);
    const [showUserProfileSidebar, setShowUserProfileSidebar] = useState(false);
    const { sideBarActive, key, setSideBarActive, setKey } = useSidebarPovider();
    const items = useMemo(() => {
        return [
            ...menuItems,
            {
                label: 'Change Password',
                // allow simple user and impersonated Client User
                template: (
                    <span
                        onClick={(e) => {
                            CloseMenu(e);
                            setShowChangePasswordSidebar(true);
                        }}
                        data-recx="penel_opener"
                        className="p-menuitem-link">
                        <span className="p-menuitem-text">Change Password</span>
                    </span>
                ),
                command: () => { }
            },
            {
                label: 'User Profile',
                template: (
                    <span
                        onClick={(e) => {
                            CloseMenu(e);
                            setShowUserProfileSidebar(true);
                        }}
                        data-recx="profile_opener"
                        className="p-menuitem-link">
                        <span className="p-menuitem-text">User Profile</span>
                    </span>
                ),
                command: () => { }
            }
        ];
    }, [menuItems, CloseMenu]);

    const user = JSON.parse(localStorage.getItem('decoded_token'))
    const firstName = user?.username
    const roleType = role_mapping[user?.role_type]

    return (
        <header className="header">
            <div className="header__left">
                <div className="top__toggle">
                    <div
                        className={classNames('sidebar__toggle', { active: sideBarActive })}
                        onClick={() => {
                            if (sideBarActive) {
                                setSideBarActive(false);
                            } else {
                                setSideBarActive(true);
                            }
                        }}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <div className="logo">
                    <a className="app-logo" href={baseOriginUrl}>
                        <img src={logo} alt="Logo" />
                    </a>
                </div>
            </div>

            <div className="header__actions">
                <div className="form-field"></div>
                {children}
                <div className="action__item notification">
                    <div className="user-name">
                        <p>
                            {firstName} <br /> <strong>{roleType}</strong>
                        </p>
                    </div>

                </div>
                <div className="action__item">
                    <UserInfo {...{ items, identityServerUrl, client }} />
                </div>
            </div>

            <Sidebar
                visible={showChangePasswordSidebar}
                position="right"
                onHide={() => setShowChangePasswordSidebar(false)}
                style={{ width: '400px' }}>
                <ChangePasswordSidebar setShowChangePasswordSidebar={setShowChangePasswordSidebar} />
            </Sidebar>

            <Sidebar
                visible={showUserProfileSidebar}
                position="right"
                onHide={() => setShowUserProfileSidebar(false)}
                style={{ width: '400px' }}>
                <UserProfileSidebar setShowUserProfileSidebar={setShowUserProfileSidebar} />
            </Sidebar>
        </header>
    );
}
