import logo from 'assets/images/logo.svg';
import loader from 'assets/images/loader.gif';
import Home from 'screens/Home';
import 'react-datepicker/dist/react-datepicker';
import 'react-phone-number-input/style.css'
import JWTAuthContainer from './auth/Component/JwtAuthContainer';
import JwtHttpProvider from './auth/Provider/JwtHttpProvider';

const REACT_APP_NAME = process.env.REACT_APP_NAME;
const API_URL = process.env.REACT_APP_API_URL_PY;

function App() {
    return (
        <JwtHttpProvider {...{ apiUrl: API_URL }}>
            <JWTAuthContainer
                {...{
                    baseUrl: `/${REACT_APP_NAME}`,
                    baseOriginUrl: process.env.REACT_APP_BASE_URL,
                    client: 'SSO',
                    identityServerUrl: process.env.REACT_APP_IDENTITY_URL,
                    logo,
                    loader
                }}>
                <Home />
            </JWTAuthContainer>
        </JwtHttpProvider>
    );
}

export default App;
