import React, { createContext, useContext, useState, useEffect, Fragment } from 'react';
import { BrowserRouter, Navigate, useLocation } from "react-router-dom";
import JwtBaseRoutes from './JwtBaseRoutes';
import { SidebarProvider } from '../Provider/SidebarProvider';
import { HeaderProvider } from '../Provider/HeaderProvider';
import JwtAuthProvider from '../Provider/JwtAuthProvider';
import { useAuth } from 'auth/Hooks/useAuth';
import { useFetch } from 'use-http';

// Authentication Context and Hook
interface AuthContextProps {
  token: string | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: boolean;
}


const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProviderComponent = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));

  const { post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL, {
    //headers: { Authorization: `${accessToken}` },
    cache: 'no-cache'
  })

  const login = async (username: string, password: string) => {
    try {
      const response = await fetch('/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        const data = await response.json();
        const newToken = data.access_token;
        localStorage.setItem('token', newToken);
        setToken(newToken);
      } else {
        throw new Error('Failed to authenticate');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  const isAuthenticated = !!token;

  return (
    <AuthContext.Provider value={{ token, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// Protected Route Wrapper
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return <Fragment>{children}</Fragment>;
};

// AuthContainer Component
interface AuthContainerProps {
  baseUrl: string;
  identityServerUrl: string;
  client: string;
  logo: any;
  loader: any;
  children?: React.ReactNode;
}

const JWTAuthContainer = ({ baseUrl, identityServerUrl, client, loader, logo, children }: AuthContainerProps) => {
  return (
    <AuthProviderComponent>
      <SidebarProvider>
        <JwtAuthProvider {...{ identityServerUrl, client, baseUrl }}>
          <HeaderProvider {...{ loader, logo }}>
            <JwtBaseRoutes {...{ identityServerUrl, client }}>
              {children}
            </JwtBaseRoutes>
          </HeaderProvider>
        </JwtAuthProvider>
      </SidebarProvider>
    </AuthProviderComponent>
  );
};

export default JWTAuthContainer;
